// @.navbar - ref [v4-beta]
nav.navbar {
	// @Style
	.navbar-light {}
	.navbar-dark {}
	
	// @Home link
	.navbar-brand { }

	// @Target static and trasitional classes
	.collapse,
	.collapsing {
	
		// @nav ul
		.navbar-nav {
	
			// @nav li
			.nav-item {
	
				// @nav a
				.nav-link { }
				
				// @nav li.active
				&.active {
					.nav-link {	}
				}
			}
		}
	}
}

#main-header {
    height: 88px;
    background-color: #F2F6FD;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 10000;
	opacity: 1;

	@media screen and (max-width:700px) {
		height: 50px;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

}

.dropdown {
	position: relative;
}

.dropdown-menu {
    z-index: 999;
    background-color: #1c1947;
	color: #fff;
    position: absolute !important;
	position: absolute !important;
	top: 0;
	left: 0;
	border: 0 !important;
	border-top: 0 !important;
	margin: 0 !important;

	// &.show {
	// 	align-items: center;
	// }
}

.dropdown-item {
	color: #fff;
	padding: 0 10px;
	margin: 0;
}

.dropdown-item:after {
	color: #6dc065 !important;
	content: "  〉";
	visibility: hidden;
}

.dropdown-item:hover:after {
	color: #6dc065 !important;
	content: "  〉";
	visibility: visible;
}

#navMenuToggle {
	color: rgb(28, 25, 71);
	height: 100%;
	width: 28px;
	display: none;

	.nav-link {
		color: rgb(28, 25, 71);
		letter-spacing: .25em;
		text-transform: uppercase;
		font-size: .8125rem;
		font-weight: 700;
		font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
		transition: all .2s ease-in-out;
		height: 100%;
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		svg{
			height: 35px;
			width: 35px;

			path{
				stroke: #1C1947;
				transition: all 0.3s;
			}
			
		}
	}

	.nav-link:hover {
		color: #fff;
		stroke: white;
		// background-color: rgb(28, 25, 71);

		svg{
			
			path{
				stroke: #2f5fac;
			}
		}
	}

	.dropdown-toggle::after {
		content: none;
	}
}

@media (max-width: 1000px) {
	#main-nav {
		display: none;
	}

	#navMenuToggle {
		display: block;
	}

	#main-header {
		justify-content: space-between;
		padding: 0 20px;
	}
}


/* Mobile nav */
.mobileNav {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	z-index: 100;
	background: linear-gradient(90deg,hsla(0,0%,100%,.25),hsla(0,0%,100%,.95) 80%),url('/assets/images/connico-pattern-tile.svg'),#e4ebf5;
	// display: none;
	transition: transform 0.5s ease-in-out;
	transform: translateX(100%);

	.top {
		padding: 25px 15px;
		display: flex;
		justify-content: space-between;

		a {
			text-align: right;
			box-sizing: border-box;
			display: block;
			font-family: Montserrat,Open Sans,century gothic,helvetica,Segoe UI,arial,sans-serif;
			font-weight: 700;
			font-size: .8125rem;
			margin-top: 1rem;
			margin-right: 1rem;
			color: #1c1947;
			text-transform: uppercase;
			letter-spacing: .25em;
			line-height: 1.375;
    		-webkit-font-smoothing: antialiased;
			text-decoration: none;
		}

		#closeMobileNavBtn {
			margin-top: 1.75rem;
		}
	}

	.bottom {
		ul {
			padding: 0;
			margin: 0;
			
			li {
				@media (min-width: 640px) {
					font-size: 24px;
				}
				letter-spacing: .25em;
				text-transform: uppercase;
				padding-left: 2rem;
				padding-right: 2rem;
				margin-bottom: 1.5rem;
				font-size: 1.125rem;
				font-weight: 700;
				font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
				transition: all .2s ease-in-out;
				transition-property: all;
				transition-duration: .26s;
				transition-timing-function: ease-in-out;
				text-decoration: none;
				background-color: transparent;
				box-sizing: border-box;
				cursor: pointer;
				text-align: right;
				position: relative;
				color: #1c1423;
				line-height: 1.375;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				overflow-x: hidden;
			}

			li:hover {
				color: #fff;
				background-color: #2f5fac;
			}
		}
	}
}

.showNav {
	display: block;
	transform: translateX(0) !important;
}

/* The following is used for header images on the projects + projects categories pages */
.header-img {
	object-position: center;
	object-fit: cover;
    height: 16rem;
	width: 100%;
}