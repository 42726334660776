.sep {
    margin: 20px 0 !important;
    border: 0 !important;
    opacity: 1 !important;
    height: 3px;
    width: 100px;
}

.sep-left {
    display: flex;
    justify-content: left;
}