.hero {
    .content {
        // max-width: 68.75rem;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;

        .left {
            padding-right: 1.25rem;
            max-width: 30rem;
            box-sizing: border-box;

            .title {
                font-size: 2.25rem;
                color: #fff;
                line-height: 1.25;
                font-weight: 500;
                font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                margin: 0;
                padding-top: 90px;
                width: 460px;
                box-sizing: border-box;
            }

            .subtitle {
                color: #fff;
                padding-right: 5rem;
                margin: 0;
                box-sizing: border-box;
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                width: 460px;
                font-weight: 300;
                padding-bottom: 150px;
            }
        }
        .left-alt {
            padding-right: 1.25rem;
            max-width: 50rem;
            box-sizing: border-box;

            .title {
                font-size: 68px;
                line-height: normal;
                color: #fff;
                font-weight: 500;
                font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                padding-top: 0;
                width: 680px;
                box-sizing: border-box;

                @media  screen and (max-width:991px) {
                    font-size: 40px !important;
                    width: 100%;
                }

                @media  screen and (max-width:700px) {
                    font-size: 28px !important;
                }

                #word-rotator {
                    color: #99F78F;
                    transition: opacity 300ms ease-in-out; /* Optional CSS transition */
                }
                
            }

            .subtitle {
                color: #fff;
                padding-right: 5rem;
                margin: 0;
                box-sizing: border-box;
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                width: 460px;
                font-weight: 300;
                padding-bottom: 150px;
            }
        }
    }

    
}

@media (max-width: 1000px) {
    .hero {
        .content {
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            padding: 0 28px;
        }
    }
}

@media (max-width: 700px) {
    .hero {
        .content {
            padding: 0 10px;
            .title {
                font-size: 32px;
                line-height: 1.25;
                margin: 0;
                padding: 0;
                padding-top: 90px;
                width: 100% !important;
                max-width: 100%;
            }

            .subtitle {
                padding-right: 5rem;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                width: 100% !important;
                max-width: 100%;
                font-weight: 300;
                padding-bottom: 150px;
            }
        }
    }
}