.row-stretch {
    align-items: stretch !important;
}

.pull-up{
    & > .wp-block-group__inner-container{
        // margin-top: -50px;
        position: relative;
        z-index: 200;
        display: block;
        padding-bottom: 50px;
        
        & > .wp-block-group{
            z-index: 2001;
            gap: 0;
            margin-top: -90px;
            
            @media screen and (max-width:991px) {
                max-width: 95%;
                margin-top: -40px;
            }
            @media screen and (max-width:700px) {
                max-width: 100%;
                margin-top: 0;
            }
        }
        @media screen and (max-width:991px) {
            padding-bottom: 20px;
        }
        @media screen and (max-width:700px) {
            padding-bottom: 0;
        }

    }
}

.rounded{
    & > .wp-block-group__inner-container{
        border-radius: 15px;

        @media screen and (max-width:700px) {
            border-radius: 0 !important;
        }
        
    }
    img{
        border-radius: 15px;
        
    }
    @media screen and (max-width:700px) {
        border-radius: 0 !important;
    }
}