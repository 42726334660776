.project-container {
    .top-banner {
        height: 248;
        max-height: 248px;
        overflow: hidden;
        width: 100%;
        margin-bottom: 2rem;
        position: relative;

        img {
            object-position: center;
            object-fit: cover;
            height: 248px;
            max-height: 248px;
            width: 100%;
        }
    }

    .head {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 100px;

        img {
            width: 544px;
            height: 400px;
            object-fit: cover;
            margin-top: -9rem;
            z-index: 1;
            padding: 10px;
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 448px;
            padding: 10px;
            
            p {
                font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                font-size: 18px;
                font-style: italic;
                font-weight: 500;
                line-height: 24.75px;
                text-decoration: none solid rgb(28, 20, 35);
                word-spacing: 0px;
            }
    
            h1 {
                font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                font-size: 36px;
                font-weight: 600;
                line-height: 49.5px;
                word-spacing: 0px;
                color: #1c1947;
                margin-bottom: 16px;
            }
        }
    }

    .details {
        max-width: 600px;
        padding: 12px;
        margin: 50px auto;

        p {
            font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 1em;
            line-height: 22px;
            color: #1c1423;
        }
        
        h2 {
            text-align: center;
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 15px;
            color: #1c1423;
        }

        blockquote {
            max-width: 565px;
            border-left: 2px solid #6dc065;
            padding: 0 20px;
            margin: 25px auto;
            color: #1C1423;
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
            line-height: 24.75px;
            word-spacing: 0px;

            P {
                margin: 0;
            }
        }
    }

    .stats {
        ul  {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: stretch;
            width: fit-content;
            margin: 25px auto;
            gap: 25px;

            li {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 10px;
                border-left: 1px solid black;
                color: #1c1423;
                max-width: 250px;

                span:first-child {
                    font-size: 2.25rem;
                    font-weight: 700;
                    color: #2f5fac;
                }
            }

        }
    }

    .image-gallery {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 75px 0;

        .info {
            max-width: 240px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            gap: 25px;
            margin-right: 50px;
            padding-left: 25px;

            .title {
                font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                font-size: 24px;
                font-weight: 600;
                line-height: 33px;
                text-align: left;
                word-spacing: 0px;
                color: #1C1423;
            }

            .subtitle {
                font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                font-size: 16px;
                line-height: 22px;
                word-spacing: 0px;
                color: #1C1423;
            }
        }

        .projectCarousel {
            overflow-x: hidden;
            overflow-y: hidden;
        }
    }

    .project-quote-container {
        padding: 3rem 0;

        .project-quote {
            margin: 0 auto;
            padding: 6rem 1rem;
            max-width: 53rem;
            width: 90%;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            gap: 40px;

            .quote-icon {}

            .quote-content {
                font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 24.75px;
                text-align: center;
                word-spacing: 0px;
                color: #1C1423;
                max-width: 512px;
                width: 100%;

                &::before {
                    content: open-quote;
                }
                &::after {
                    content: close-quote;
                }
            }

            .quote-source-container {
                .quote-source {
                    display: block;
                    font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                    font-size: 13px;
                    font-weight: 700;
                    letter-spacing: 3.25px;
                    line-height: 17.875px;
                    text-align: center;
                    text-transform: uppercase;
                    word-spacing: 0px;
                    color: #1C1423;
                }

                .quote-source-title {
                    margin-top: 5px;
                    display: block;
                    font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                    font-size: 13px;
                    font-style: italic;
                    font-weight: 300;
                    line-height: 17.875px;
                    text-align: center;
                    word-spacing: 0px;
                    color: #1C1423;
                }
            }
        }
    }
}

.pagination-footer {
    display: flex;
    //flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 192px;
    max-height: 192px;

    .next-prev {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: relative;
        background-color: rgba(200, 200, 200, 0.75);
        color: #1c1947;
        text-decoration: none;
        padding: 20px;

        .next-prev-bg {
            filter: grayscale(1);
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: -1;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
        }

        h2 {
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 28px;
            font-weight: 600;
            line-height: 32px;
            word-spacing: 0px;
            text-align: center;
        }

        p {
            font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 16px;
            line-height: 22px;
            word-spacing: 0px;
            text-align: justify;
        }

        &:hover {
            background-color: rgba(47, 95, 172, 0.8);
            color: #fff;

            a, h2, p {
                color: #fff;
            }
        }
    }        
}

.past-projects-container {
    margin: 5rem auto;

    h2 {
        font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 36px;
        font-weight: 700;
        line-height: 45px;
        text-align: center;
        word-spacing: 0px;
        margin-bottom: 64px;
        position: relative;

        &::after {
            content: "";
            background-color: #6dc065;
            height: 3px;
            width: 93px;
            bottom: -32px;
            left: calc(50% - 46.5px);
            position: absolute;
            display: block;
        }
    }

    .projects-img-container {
        position: relative;

        .projects-img-bg {
            width: 100%;
            bottom: 25%;
            top: 25%;
            left: 0;
            position: absolute;
            background-color: #f1f5fa;
            box-sizing: border-box;
            display: block;
            z-index: -1;
        }

        .projects-img {
            display: block;
            max-width: 56rem;
            width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }

    .past-projects {
        max-width: 1100px;
        margin: 50px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        gap: 20px 20px;
        flex-wrap: wrap;

        .past-project {
            max-width: 350px;
            width: 100%;
            padding: 20px;
            border: 2px solid lightgray;
            box-shadow: 3px 3px 3px 0 rgba(47, 95, 172, 0.5);//rgba(0,0,0,0.51);
            font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            color: #212529;
            background-color: #fff;

            h3 {
                font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                font-weight: 600;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin: 12px 0;
                    padding: 0;
                }
            }
        }
    }
}

@media (max-width: 1350px) {
    .project-container {
        .image-gallery {
            flex-direction: column;

            .info {
                margin-bottom: 35px;
                max-width: 576px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .project-container {
        .head {
            flex-direction: column;
            text-align: center;

            img {
                width: 400px;
                margin-top: 10px;
                z-index: 0;
            }

            .info {
                padding: 0px;
            }
        }

        .stats {
            ul  {
                flex-direction: column;
                
                li {
                    max-width: 90%;
                }
            }
        }

        .image-gallery {
            .info {
                margin-bottom: 25px;
            }
        }
    }
}

@media (max-width: 726px) {
    .past-projects-container {
        .past-projects {
            .past-project {
                max-width: 90%;
                width: 100%;
            }
        }
    }
}


@media (max-width: 925px) {
    .pagination-footer {
        height: 100% !important;
        max-height: unset !important;
        flex-wrap: wrap;

        .next-prev {
            h2 {
                font-size: 14px;
                line-height: 16px;
            }

            p {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
}

@media (min-width: 625px) and (max-width: 750px) {
    .pagination-footer {
        height: 200px;
        max-height: 200px;

        .next-prev {
            h2 {
                font-size: 16px;
                line-height: 20px;
            }

            p {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}

@media (min-width: 750px) and (max-width: 1200px) {
    .pagination-footer {
        height: 200px;
        max-height: 200px;

        .next-prev {
            h2 {
                font-size: 20px;
                line-height: 24px;
            }

            p {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}
