.ff-montserrat{
    font-family: "Montserrat", "Open Sans", century gothic, helvetica, Segoe UI, arial, sans-serif;
}
.ff-mon{
    @extend .ff-montserrat;
}
.ls-2{
    letter-spacing: 2px;
}

h1,h2,h3,h4,h5,h6{
    @extend .ff-mon;
    font-weight: 600;
}

@media  screen and (max-width:991px) {
    h3{
        font-size: 22px !important;
        line-height: 1.2;
    }
    h2{
        font-size: 26px !important;
        line-height: 1.2 !important;
    }
}
.fz-14 {
    font-size: 14px !important;
    line-height: 1.2;
}
.fz-16 {
    font-size: 16px !important;
    line-height: 1.2;
}

.fz-17 {
    font-size: 17px !important;
    line-height: 1.2;
}

.fz-18 {
    font-size: 18px;
    line-height: 28px;
    @media  screen and (max-width:991px) {
        font-size: 16px !important;
        line-height: 1.2;
    }
}

.fz-20 {
    font-size: 20px;
    line-height: 30px;
    @media  screen and (max-width:991px) {
        font-size: 18px !important;
        line-height: 1.2;
    }
}

.fz-22 {
    font-size: 22px;
    line-height: 26px;
    @media  screen and (max-width:991px) {
        font-size: 18px !important;
        line-height: 1.2;
    }
}

.fz-26 {
    font-size: 26px;
    line-height: 1.3;
    @media  screen and (max-width:991px) {
        font-size: 22px !important;
        line-height: 1.2;
    }
}

.fz-28 {
    font-size: 28px;
    line-height: 1.3;
    @media  screen and (max-width:991px) {
        font-size: 22px !important;
        line-height: 1.2;
    }
}

.fw-500{
    font-weight: 500;
}

.pt-sm-28{
    @media  screen and (max-width:700px) {
        padding-top: 28px !important;
    }
}
.pb-sm-28{
    @media  screen and (max-width:700px) {
        padding-bottom: 28px !important;
    }
}
.py-sm-28{
    @media  screen and (max-width:700px) {
        padding-top: 28px !important;
        padding-bottom: 28px !important;
    }
}

.pt-50 {
    padding-top: 50px;
    @media  screen and (max-width:700px) {
        padding-top: 12px;
    }
}

.pt-lg-32 {
    @media  screen and (min-width:992px) {
        padding-top: 32px !important;
    }
}

.pb-lg-32 {
    @media  screen and (min-width:992px) {
        padding-bottom: 32px !important;
    }
}

.pb-50 {
    padding-bottom: 50px;
    @media  screen and (max-width:700px) {
        padding-bottom: 12px;
    }
}

.pl-32 {
    padding-left: 32px;
}

.pr-32 {
    padding-right: 32px;
}

.pl-80 {
    padding-left: 80px;
    @media  screen and (max-width:991px) {
        padding-left: 16px;
    }
    @media  screen and (max-width:700px) {
        padding-left: 0;
    }
}

.pr-80 {
    padding-right: 80px;
    @media  screen and (max-width:991px) {
        padding-right: 16px;
    }
    @media  screen and (max-width:700px) {
        padding-right: 0;
    }
}

.pl-10p {
    padding-left: 10%;
    @media  screen and (max-width:700px) {
        padding-left: 12px;
    }
}

.pr-10p {
    padding-right: 10%;
    @media  screen and (max-width:700px) {
        padding-right: 12px;
    }
}

.pl-20p {
    padding-left: 20%;
    @media  screen and (max-width:700px) {
        padding-left: 12px;
    }
}

.pr-20p {
    padding-right: 20%;
    @media  screen and (max-width:700px) {
        padding-right: 12px;
    }
}

.transition {
    transition: all 300ms;
}

.bg-blue {
    background-color: #1C1947;
    color: white;
}

.bg-white {
    background-color: white;
}

.text-dark-blue {
    color: #1C1947;
}

.text-blue {
    color: #2f5fac;
}

.bg-light-blue {
    background-color: #428AFF;
    color: white;
}

.text-light-blue {
    color: #428AFF !important;
}

.bg-green {
    background-color: #99F78F !important;
    color: #1C1947;
}

.text-green {
    color: #99F78F;
}

.bg-light-gray {
    background-color: #F2F6FD !important;
    color: #1C1947;
}

.text-light-gray {
    color: #F2F6FD;
}

.link-white,
.link-white a {
    color: white !important;
    @extend .fz-20;
    font-weight: 700;

    &:hover {
        color: white !important;
    }
}

.rounded-8 {
    border-radius: 8px;
}

.rounded-16{
    border-radius: 16px;
    @media  screen and (max-width:700px) {
        border-radius: 0;
    }
}

.vertically-centered {
    align-items: stretch;

    .wp-block-group {
        display: flex;
        height: 100%;
        align-items: center;
        width: 100%;
    }
}

.gap-0{
    gap: 0;
}

.separator {
    >div {
        border-left: 1px solid #D8D8D8;

        &:first-child {
            border-left: none;
        }

        @media screen and (max-width:991px){
            border-left: none !important;
        }
    }
}

a.btn-white,
button.btn-white,
.btn-white a {
    background-color: white;
    color: #1C1947;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms;
    text-decoration: none;
    @extend .fz-16;
    font-weight: 700;
    @extend .ff-mon;

    &:hover {
        background-color: #1C1947;
        color: #fff;

        &::after {
            background-color: #fff !important;
        }
    }

    &::after {
        background-color: #1C1947 !important;
    }
}

a.btn-light-blue,
button.btn-light-blue,
.btn-light-blue a {
    color: white;
    background-color: #428AFF !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms;
    text-decoration: none;
    border-radius: 1.5rem !important;
    font-size: 16px;
    font-weight: 700;
    @extend .ff-mon;

    &:hover {
        background-color: #1C1947 !important;
    }
}

a.btn-blue,
button.btn-blue,
.btn-blue a {
    color: white;
    background-color: #1C1947 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms;
    text-decoration: none;
    border-radius: 0 !important;
    font-size: 16px;
    font-weight: 700;
    @extend .ff-mon;

    &:hover {
        background-color: #2f5fac !important;
        color: white !important;

        &::after {
            background-color: #fff !important;
        }
    }

    &::after {
        background-color: white !important;
    }
}

a.btn-green,
button.btn-green,
.btn-green a {
    color: #1C1947;
    background-color: #99F78F !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    @extend .ff-mon;

    &:hover,
    &:hover a {
        color: #fff !important;
        background-color: #1C1947 !important;

        &::after {
            background-color: #fff !important;
        }
    }

    &::after {
        background-color: #1C1947 !important;
    }
}

a.btn-rectangle,
button.btn-rectangle,
.btn-rectangle a {
    border-radius: 0 !important;

    &:hover {
        border-radius: 0 !important;
    }
}

a.btn-sm,
button.btn-sm,
.btn-sm a {
    padding: 6px 18px;
    font-size: 14px;
    font-weight: 600;
}
a.btn-md,
button.btn-md,
.btn-md a {
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 600;

    // @media screen and (max-width:700px) {
    //     font-size: 24px;
    //     padding: 12px 20px;
    // }
}

a.btn-lg,
button.btn-lg,
.btn-lg a {
    padding: 22px 28px;
    // height: 130px;
    // width: 368px;
    font-size: 30px;
    font-weight: 600;

    @media screen and (max-width:1090px) {
        font-size: 26px;
        padding: 12px 20px;
    }
    @media screen and (max-width:700px) {
        font-size: 24px;
        padding: 12px 20px;
    }
}
a.btn-nowrap,
button.btn-nowrap,
.btn-nowrap a {
    white-space: nowrap;
}



a.btn-arrow-lg,
button.btn-arrow-lg,
.btn-arrow-lg a {
    &::after {
        // content: url('/assets/images/right-arrow.svg');
        margin-left: 8px;
        width: 32px;
        height: 25px;
        display: inline-block;
        content: '';
        -webkit-mask: url('/assets/images/right-arrow.svg') no-repeat 50% 50%;
        mask: url('/assets/images/right-arrow.svg') no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        @extend .transition;
    }

    @media screen and (max-width:700px) {
        &::after {
            width: 25px;
            height: 20px;
        }
    }
}

.bb-white {
    border-bottom: 1px solid white;
}

.bb-light-blue {
    border-bottom: 4px solid #428AFF;
}

a.text-white {
    color: white !important;
    text-decoration: none;

    &:hover{
        text-decoration: underline;
    }
}

a {
    transition: all 300ms;
}

p{
    @extend .fz-18;
}

a.blue-link,
.blue-link a {
    @extend .fz-20;
    @extend .ff-mon;
    font-weight: 700;
    color: #428AFF !important;
    text-decoration: none;
    border-bottom: 2px solid #1C1947;
    letter-spacing: 2px;

    &:hover {
        color: #1C1947 !important;
        opacity: 1 !important;
    }
}

.footer-menu {
    list-style: none;
    padding-left: 0;

    li{
        a{
            line-height: 30px;
            @extend .transition;

            &:hover{
                text-decoration: underline;
            }
        }
        &.current-menu-item{
            a{
                font-weight: 700;
            }
        }
    }
}

ul.list-arrows {
    margin-left: 0;
    padding-left: 8px;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    @extend .fz-18;
    line-height: 28px;

    li {
        padding-left: 18px;
        position: relative;
        &::before {
            content: ">" !important;
            color: #428AFF;
            padding-right: 6px;
            background-color: transparent !important;
            width: 10px !important;
            height: 10px !important;
            border-radius: 0 !important;
            top: auto !important;
            position: absolute;
            left: 0;
            display: inline-block;

        }

        a {
            text-decoration: none;
            font-weight: bold;
            @extend .ff-mon;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    @media screen and (max-width:700px) {
        line-height: 1.3;
        margin-bottom: 0 !important;
        li{
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
}

ul.list-inline {
    li {
        display: inline-block;
        margin-left: 12px;

        &:first-of-type {
            margin-left: 0;
        }

        @media screen and (max-width:700px) {
            display: block;
            margin-left: 0;
        }
    }
}

ul.list-spacing-md {
    li {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

ul.the-plan {
    margin-left: 0;
    padding-left: 8px;
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    li {
        border-bottom: 1px solid white;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        padding-top: 16px;
        padding-bottom: 16px;
        @extend .transition;
        padding-left: 0;

        &:before {
            @extend .transition;
            content: url('/wp-content/uploads/2024/07/green-arrow.png');
            scale: 0.8;
            width: 1px;
            opacity: 0;
            display: inline-block;
            vertical-align: middle;
        }

        span {
            color: #428AFF;
            font-weight: bold;
        }


        a {
            text-decoration: none;
            font-weight: bold;

            &:hover {
                text-decoration: underline;
            }
        }

        &:hover {
            &::before {
                opacity: 1;
                width: 50px;
            }
        }
    }
}

.show-hide-content{
    opacity: 0;
    transition: 1000ms all;
    &.fade-in{
        // transition: 1300ms all;
        // opacity: 1;
        animation-duration: 300ms;
        animation-name: fadeIn;

    }
    &.show-group{
        display: flex !important;
        opacity: 1;
    }
    &.fade-out{
        animation-duration: 200ms;
        animation-name: fadeAway;
        // transition: 1000ms all;
        // opacity: 0;
    }
    &.hide-group{
        display: none !important;
        opacity: 0;
    }
}
@keyframes fadeAway {
    0% {
        opacity: 1;
        display: flex !important;
    }
    99% {
      opacity: 0;
      display: flex !important;
    }
    100% {
      opacity: 0;
      display: none !important;
    }
  }
@keyframes fadeIn {
    0% {
        // opacity: 0;
        display: flex !important;
    }
    100% {
      opacity: 1;
      display: flex !important;
    }
  }

.container.narrow{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.section-md {
    padding-top: 84px;
    padding-bottom: 84px;
    
    @media screen and (max-width:700px) {
        padding-top: 28px;
        padding-bottom: 28px;
    }
}

.section-insights {
    .section-title{
        font-size: 30px;
        @media screen and (max-width:991px) {
            font-size: 20px !important;
        }
    }
    li {
        padding: 0;

        .wp-block-columns {
            height: 100%;

            .wp-block-column {
                background: #F2F6FD;
                padding: 20px 20px;
                border-radius: 16px;

                @media screen and (max-width:700px) {
                    padding: 6px;
                }


                figure {
                    a {
                        img {
                            margin-top: 0;
                            margin-bottom: 0;
                        }

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                h3 {
                    line-height: normal;
                    margin-bottom: 0;

                    a {
                        &:hover {
                            color: #428AFF;
                            opacity: 1;
                        }
                    }
                    &.post-title{
                        font-size: 20px !important;
                        font-weight: 500;
                        @media screen and (max-width:991px) {
                            font-size: 14px !important;
                        }
                    }
                }
                p.post-category{
                    font-size: 18px;
                    @media screen and (max-width:991px) {
                        font-size: 14px;
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }

    &.bg-light-gray {
        .wp-block-columns {
            height: 100%;

            .wp-block-column {
                background: white;
            }
        }
    }

}

@media screen and (max-width:991px) {
    .grid-tablet-1{
        grid-template-columns: 1fr !important;
    }
}

.light-gray-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr min-content;
    column-gap: 24px;
    row-gap: 24px;
    width: 100%;

    .wp-block-group {
        border-radius: 8px;

    }
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #1C1947;
        img{
            max-height: 65px;
        }
    }
    @media screen and (max-width:1199px) {
        grid-template-columns: 1fr 1fr !important;
        a{
            img{
            margin-bottom: 6px;
            }
        }

    }
    @media screen and (max-width:700px) {
        grid-template-columns: 1fr !important;
        a{
            img{
            margin-bottom: 6px;
            }
        }

    }
}
.charities{
    .light-gray-boxes {
        a{
            img{
                max-height: 200px;
                @media screen and (max-width:700px) {
                    max-height: 80px;
                }
            }
        }
    }
}

.gap-md {
    column-gap: 24px;
    row-gap: 24px;
}

.img-circle {
    border-radius: 50%;
}

.left-green-border {
    border-left: 34px solid #99F78F;

    @media screen and (max-width:700px) {
        border-left: none;
    }
}

.show-on-hover {
    opacity: 0;
    display: none !important;
    @media screen and (max-width:700px) {
        opacity: 1;
        display: block !important;
    }
}

.hide-on-hover {
    opacity: 1;
    display: block;
    transition: all 200ms;

    h3 {
        margin-bottom: 0;
    }
}

.on-hover-show {
    @extend .transition;
    cursor: pointer;
    border-bottom: 4px solid transparent;
    height: 310px !important;

    h3{
        text-align: center;
    }

    @media  screen and (max-width:700px) {
        height: auto !important;
    }


    &:hover {
        border-bottom: 4px solid #428AFF;

        .show-on-hover {
            opacity: 1;
            display: flex !important;
        }

        .hide-on-hover {
            opacity: 0;
            display: none;
        }

        *{
            text-align: left;
        }
    }

    p {
        margin-bottom: 0;
    }
}

.center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wp-block-pb-accordion-item.accordion-simple {
    background-color: transparent;
    border-top: 1px solid #A1BBE4;
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;

    .c-accordion__title {
        font-size: 16px !important;
        line-height: 24px !important;
        padding: 0;
        background-color: transparent;
        // padding-left: 16px;
        // padding-right: 16px;
        text-align: left;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        color: #428AFF;

        &::after {
            margin: 12px 20px 0 0;
            color: #1C1947;
            font-weight: 200;
            transition-duration: 120ms;
        }
    }

    .c-accordion__content {
        padding: 0;
    }

    &.is-open {
        display: flex;
        flex-direction: column;
        background-color: transparent;

        .c-accordion__title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .c-accordion__content {
            width: 100%;
        }
    }
}
.wp-block-pb-accordion-item.accordion-white {
    .c-accordion__title {
        color: white;

        &::after {
            color: white;
        }
    }
}

@media screen and (max-width:700px) {
    .wp-block-group-is-layout-grid{
        grid-template-columns: 1fr !important;
    }

    h2{
        font-size: 28px !important;
        line-height: normal !important;
    }
    
}

.home-hero{
    min-height: 76vh !important;
    @media screen and (max-width:991px) {
        min-height: 500px !important;
    }
    @media screen and (max-width:700px) {
        min-height: 310px !important;
    }
}

.w-100 .wp-block-group__inner-container{
    width: 100%;
}

.hamburger{
    padding-left: 0 !important;
    padding-right: 0 !important;
    // svg{
    //     fill: black;
    //     width: 35px;
    //     height: 35px;
    // }
    // svg{
    //     width: 35px;

    //     path{
    //         stroke: #1C1947;
    //     }
    // }
}

@media screen and (max-width:991px) {
    .container{
        max-width: 100%;
        padding-left: 22px;
        padding-right: 22px;
    }
}

@media screen and (max-width:700px) {
    .container{
        padding-left: 22px;
        padding-right: 22px;
    }
    .section-md{
        padding-left: 22px;
        padding-right: 22px;
        .container{
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.grid-3{
    grid-column: span 3;
}

.b-none{
    border: none !important;
}

form.newsletter-signup{
	.gform_body{
        #gform_fields_2{
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media screen and (max-width:991px) {
                justify-content: flex-start;
            }

            .gfield.gfield--width-quarter{
                min-width: auto;
            }

            input[type="text"], input[type="email"]{
                background: transparent !important;
                width: 100%;
                border: 1.5px solid white;
                border-radius: 8px;
                @extend .fz-16;
                font-weight: bold;
                color: white;
                height: 42px;
                vertical-align: middle;
                padding: 0 12px;
                &::placeholder{
                    color: darken(white,35);
                }
            }
            input[type="submit"], input[type="image"]{
                background: white !important;
                border: 1.5px solid white;
                border-radius: 8px;
                @extend .fz-16;
                font-weight: bold;
                color: #1C1947;
                height: 42px;
                vertical-align: middle;
                padding: 0 16px;
                letter-spacing: normal;

                &:hover{
                    transform: none;
                    color: white;
                    background-color: #1C1947 !important;
                }
//                 &::after{
//                     content: "";
//   display: inline-block;
//     cursor: pointer;
//     width: 10px;
//     height: 10px;
//     background-repeat: no-repeat;
//     background-image: url("/assets/images/right-arrow.png");

//                 }
            }
@media screen and (max-width:700px) {
    display: block;
    margin-top: 16px;

    input{
        margin-bottom: 8px;
        margin-left: 0;
        width: 100%;
    }
}
        }
	}
    .gform_footer.top_label{
        display: none !important;
    }
}