.uniform-grid {
    margin: 0 auto;
    
    .row {
        height: fit-content !important;
        margin: 25px auto;

        .wp-block-group {
            h2 {
                font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                font-size: 24px !important;
                font-weight: 600;
                line-height: 33px;
                text-align: left;
                word-spacing: 0px;
                color: #1C1423;
            }
        
            p, span {
                font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
                font-size: 16px;
                line-height: 22px;
                text-align: left;
                word-spacing: 0px;
                color: #1C1423;
            }
        }
    }

    .row > * {
        flex-shrink: 1;
        text-align: justify !important;
    }
}