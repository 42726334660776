/* Posts on blog/category page */
.posts-container, .projects-container {
    max-width: 1100px;
    margin: 15px auto;
}

.posts-filter, .projects-filter {
    margin: 20px 30px;
    font-size: 24px;
    line-height: 30px;
    color: #1c1423;
    font-weight: 700;

    .select-wrap {
        position: relative;
        select {
            border: none;
            font-size: 20px;
            margin: 0 15px;
            appearance: none;
            padding: 0 28px 0 8px;
        }
    }

    .select-wrap::after {
        content: '⌄';
        padding: 0;
        margin: 0;
        pointer-events: none;
        position: absolute;
        bottom: 7px;
        right: 20px;
        color: #2F5FAC;
        font-size: 32px;
    }
}

.blog-posts, .projects {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: stretch;
    flex-wrap: wrap;
    list-style: none;
    gap: 20px 20px;
}

.blog-post, .project {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: stretch;
    max-width: 524px;
    width: 100%;
    max-height: 400px;
    height: 400px;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    flex: 1 1 auto;

    &:nth-child(3n+1) {
        max-width: 1100px;
        width: 100%;

        .left {
            max-width: 40%;
        }

        .right {
            display: block;
            max-width: 60%;
            object-position: center;
            object-fit: cover;
        }
    }

    .left {
        max-width: 100%;
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .categories {
            margin: 0;
            padding: 0;
        }

        h3 {
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 3.25px;
            line-height: 17.875px;
            text-transform: uppercase;
            word-spacing: 0px;
            color: #1c1423;
        }

        a {
            text-decoration: none;
        }

        h2 {
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 1.5em;
            line-height: 33px;
            color: #1c1423;
            font-weight: 700;
        }

        p {
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 1em;
            line-height: 22px;
            color: #1c1423;
            font-weight: 300;
        }

        .view-more-btn {
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 13px;
            line-height: 17.875px;
            letter-spacing: 3.25px;
            background-color: #2F5FAC;

            &::after {
                content: " 〉";
            }
        }
    }

    .right {
        display: none;
    }
}

.filter-info {
    margin: 20px 30px;

    h2 {
        font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 24px;
        line-height: 33px;
        color: #1c1947;
        font-weight: 500;

        .category-highlight {
            color: #6dc065;
        }
    }

    a {
        font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #2f5fac;
        text-decoration: none;

        &:hover {
            color: #6dc065;
        }
    }
}

@media (max-width: 1150px) {
    .posts-filter, .projects-filter {
        margin: 0 auto;
        width: fit-content;
    }

    .blog-posts, .projects {
        max-width: 100%;
        gap: 10px 20px;
        margin: 15px auto;
        padding: 20px;
    }

    .blog-post, .project {
        max-width: 100%;
        width: 100%;
        max-height: initial;
        height: 100%;
    }
}

@media (max-width: 800px) {
    .blog-post, .project {
        &:nth-child(3n+1) {
            .left {
                max-width: 100%;
                width: 100%;
            }

            .right {
                display: none;
            }
        }
    }
}