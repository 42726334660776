.sidebyside3 {
    padding: 40px 0 20px 0;
    position: relative;

    .left {
        padding-left: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        padding-bottom: 40px;
    }

    .left::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 50%;
        background-color: #2f5fac;
        z-index: -1;
    }

    .right {
        ul {
            display: flex;
            justify-content: left;
            flex-wrap: wrap;
            list-style: none;
            padding-right: 15%;
            gap: 20px 30px;

            li {
                background-color: white;
                padding: 20px 15px 80px 15px;
                width: 245px;
                box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                font-size: 1.5625rem;
                color: #2f5fac;
                margin-bottom: 0.75rem;
                font-weight: 600;
                font-family: Montserrat,Open Sans,century gothic,helvetica,Segoe UI,arial,sans-serif;
                transition: all .2s ease-in-out;
            }

            li:hover {
                background-color: #2f5fac;
                color: #fff;
            }
        }
    }

    .right:before {
        content: "";
        width: 100%;
        height: 100%;
        background-image: url('/assets/images/connico-pattern-tile.svg');
        left: 0;
        position: absolute;
        bottom: 0;
        --bg-opacity: 1;
        background-color: #e4ebf5;
        background-color: rgba(228,235,245,var(--bg-opacity));
        z-index: -2;
    }

    @media (max-width: 780px) {
        .sidebyside3 {}        

        .left {
            padding: 0 20px;
        }

        .left::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 55%;
            left: 0;
            right: 0;
        }

        .right {
            ul {
                padding: 0;
                margin: 10px 0 0 0;
                justify-content: center;
                gap: 8px;
                padding: 10px 3px;

                li {
                    font-size: 1rem;
                    flex-basis: 47%;
                    margin: 0;
                }
            }
        }

        .right:before {
            width: 100%;
            left: 0;
            bottom: 0;
            top: 45%;
            height: 55%;
        }
    }
}