.navbar {
    height: 100%;
    padding: 0;

    .navbar-nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
    
        .nav-item {
            color: rgb(28, 25, 71);
            height: 100%;

            &.current-menu-parent,
            &.current-menu-item{
                background-color: #D8E2F6;
            }
    
            .nav-link {
                color: rgb(28, 25, 71);
                letter-spacing: .25em;
                text-transform: uppercase;
                font-size: .8125rem;
                font-weight: 700;
                font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
                transition: all .2s ease-in-out;
                height: 100%;
                padding-left: 1rem;
                padding-right: 1rem;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
    
            .nav-link:hover {
                color: #fff;
                background-color: rgb(28, 25, 71);
            }

            .dropdown-toggle::after {
                content: none;
            }
        }
    }
}

.dropdown-menu {
    display: none;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 30px 20px;
    gap: 60px;
    font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 29.4px;
    word-spacing: 0px;
    max-width: fit-content;

    &.show {
        display: flex;
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .img-container {
            position: relative;
            width: 224px;
            height: 160px;
            display: none;

            .content {
                width: 100%;
                height: 100%;
                position: relative;
                top: 0;
                left: 0;

                &:after {
                    content: "";
                    background: rgba(47,95,172,.8);
                    mix-blend-mode: multiply;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                    filter: grayscale(1);
                }

                .p-container {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 50;
    
                    p {
                        font-size: 12px;
                        line-height: 16px;
                        margin: 10px;
                        border-left: 1px solid #6dc065;
                        padding: 0 0 0 10px;
                    }
                }
            }
            

            

            // .bg {
            //     background: rgba(47,95,172,.8) !important;
            //     mix-blend-mode: multiply;
            //     position: absolute;
            //     top: 0;
            //     bottom: 0;
            //     left: 0;
            //     right: 0;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            // }

            &:first-child {
                display: block;
            }
        }
    }
}