.cards-grid {
    padding: 50px 25px 100px 25px;

    .title {
        max-width: 600px;
        margin: 90px 0 0 0 !important;
        font-weight: 700;
        font-size: 2.25rem;
        font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
        line-height: 1.25;
    }
    
    .subtext {
        max-width: 600px;
        margin: 0 0 80px 0 !important;
        font-family: 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
        line-height: 1.375;
        -webkit-font-smoothing: antialiased;
    }
    
}


.cards-grid-cards {
    max-width: 1000px;
}

@media (min-width: 782px){
    .card-wrap {
        height: 100%;
    }
}

.card {
    padding: 40px;
    height: 100%; /* This makes everything in the row the same height */
    border-radius: 0;
    position: relative;
    transition: all .5s ease-in-out;
    h2 {
        font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 25px;
        font-weight: 600;
        line-height: 34.375px;
        word-spacing: 0px;
        color: #5F84BF;
    }

    p {
        font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 16px;
        line-height: 22px;
        word-spacing: 0px;
    }

    .card-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        text-align: right;
        margin-top: auto;
        font-weight: 700;
        font-size: 24px;

        a {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            text-decoration: none;
            color: inherit;
            width: 100%;
            height: 100%;
            padding: 10px;

            &:hover {
                opacity: 0.5;
                color: lightgreen;
            }
        }
    }

    &:hover {
        background-color: #2f5fac !important;

        h2, p {
            color: #fff !important;
            transition: all .5s ease-in-out;
        }

        a {
            color: lightgreen;
        }
    }
}