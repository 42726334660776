//@footer
#main-footer {
    margin: 0;
    padding: 0;
}

footer {
    .top-footer {
        background-color: rgb(228, 235, 245);
        background-image: url('/assets/images/connico-pattern-tile.svg');
        background-repeat: repeat;
        margin: 0;
        padding: 5rem 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 50px;

        .footer-logo {
            margin: 0 0 auto 0;
        }

        .footer-contents {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px;

            .footer-item {
                min-width: 200px;

                p:first-child {
                    letter-spacing: 0.15rem;
                    text-transform: uppercase;
                    margin-bottom: 0.5rem;
                    font-size: .75rem;
                    font-weight: 700;
                    color: rgb(28, 25, 71);
                    font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
                }

                p {
                    color: rgb(28, 25, 71);
                    font-family: 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
                    margin: 0;
                    padding: 0;
                }

                .social-link {
                    width: 1rem;
                    color: rgb(28, 25, 71);
                    fill: currentColor;
                    margin: 0.5rem;
                    display: inline-block;
                    transition: all .2s ease-in-out;
                }

                a {
                    color: rgb(28, 25, 71);
                    text-decoration: none;
                }

                a:hover {
                    opacity: 0.6;
                }
            }
        }
    }

    .bottom-footer {
        background-color: rgb(28, 25, 71);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
        margin: 0;
        padding: 15px;
        gap: 10px;

        p {
            margin: 0;
            padding: 0;
            font-size: 1rem;
            font-family: 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
        }

        div:first-child {
            p:first-child {
                font-size: 1.25rem;
            }

            p {
                font-family: 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
            }
        }
    }
}