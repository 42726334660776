.career-opportunities {
    max-width: 1100px;
    margin: 0 auto;
    padding: 80px 15px;

    .resumator-job {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .resumator-job-title {
            font-family: Open Sans,century gothic,helvetica,Segoe UI,arial,sans-serif;
            font-size: 1.5em;
            margin: 40px 0 0;
            padding: 15px 0 0;
        }
        .resumator-job-info span {
            font-family: Open Sans,century gothic,helvetica,Segoe UI,arial,sans-serif;
            font-size: 13px;
        }
        .resumator-job-view-details {
            margin-top: 8px;
            .resumator-job-link {
                color: white;
                text-decoration: none;
                text-transform: uppercase;
                font-family: Montserrat,Open Sans,century gothic,helvetica,Segoe UI,arial,sans-serif;
                font-size: 13px;
                line-height: 17.875px;
                letter-spacing: 3.25px;
                background-color: #2f5fac;
                padding: 16px 24px;
                transition-property: all;
                transition-duration: .26s;
                display: inline-block;
                &:hover {
                    transform: translateY(-3px);
                    box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
                    background-color: #6dc065!important;
                    border: none;
                }
            }
        }
    }
}