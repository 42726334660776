.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    list-style: none;

    .pagination-arrow {
        font-size: 2em;
        font-weight: 700;
        margin: 0;
        padding: 0 12px;

        a {
            margin: 0;
            padding: 0;
            text-decoration: none;
            color: #2F5FAC;
            height: fit-content;

            &.disabled {
                opacity: 0.2;
                color: currentColor;

                &:hover {
                    opacity: 0.2;
                    cursor: none;
                    color: currentColor;
                }
            }
        }

        &:hover {
            opacity: 0.7;
            cursor: pointer;
            background-color: #f4f5f6;
        }
    }

    .page-number {
        font-size: 1em;
        padding: 1em;

        a {
            text-decoration: none;
            color: #1C1323;
        }

        &:hover {
            background-color: #f4f5f6;
        }

        &.current {
            font-weight: 700;
            background-color: #6DC065;

            a {
                color: #fff;
            }
        }
    }
}