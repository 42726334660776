.contact {
    .page-content {
        a {
            text-decoration: none;
            font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            text-align: center;
            word-spacing: 0px;
            color:  #2F5FAC;
            transition-property: all;
            transition-duration: .26s;
            transition-timing-function: ease-in-out;
    
            &:hover {
                color: #6dc065;
                transform: translateY(-1px);
                transition-property: all;
                transition-duration: .26s;
                transition-timing-function: ease-in-out;
                border-bottom: 2px solid #6dc065;
            }
        }
    
        .phone-link a {
            text-decoration: none;

            &:hover {
                border-bottom: none;
            }
        }
    }
}

.gform_confirmation_message {
    text-align: center;
}