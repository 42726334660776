.callout {
    padding: 50px 50px 75px 50px;
}

.callout-content {
    max-width: 1100px !important;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .btn a {
        text-decoration: none;
        transition-property: all;
        transition-duration: .26s;
        display: inline-block;
        font-size: .8125rem;
        padding: 1rem 1.5rem;
        color: #fff;
        font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
        font-weight: 600;
        letter-spacing: .25em;
        text-transform: uppercase;
        transform: translateY(0);
        margin-top: 20px;
    }
    
    .btn a:hover {
        transform: translateY(-3px);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        background-color: #6dc065 !important;
    }
}