.banner-btn-left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 44%;
    right: 56%;
    max-height: 67.5px;
    overflow-y: hidden;
}

.banner-btn-left a {
    width: 100%;
    height: 100%;
    text-align: right;
    text-decoration: none;
    font-weight: 700;
    border-radius: 0;
    font-size: .8125rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: #fff;
    text-align: right;
    font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
    font-weight: 600;
    letter-spacing: .25em;
    text-transform: uppercase;
    padding-right: 1.5rem;
    transition-property: all;
    transition-duration: .26s;
    transition-timing-function: ease-in-out;

    span {
        display: inline-block;
        position: relative;
        transition-property: all;
        transition-duration: .26s;
        transition-timing-function: ease-in-out;
    }

    span::after {
        content: "";
        height: 2px;
        background-color: #fff;
        bottom: 0;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        position: absolute;
        left: 0;
        z-index: 0;
        border-radius: 0.25rem;
        opacity: 0;
        transition-property: all;
        transition-duration: .26s;
        transition-timing-function: ease-in-out;
    }
}

.banner-btn-left a:hover {
    background-color: #6dc065 !important;

    span {
        transform: translateY(-5px);
        padding-bottom: 5px;
    }

    span::after {
        // display: inline-block;
        // padding-bottom: 2px;
        // transform: translateX(3px);
        // transition-property: all;
        // transition-duration: .26s;
        // transition-timing-function: ease-in-out;
        // border-bottom: 2px solid #fff;
        opacity: 1;
        left: 20px;
        right: 40px;
        width: 93%;
    }

    &::after {
        display: inline-block;
        transform: translateY(9px);
        transition-property: all;
        transition-duration: .26s;
        transition-timing-function: ease-in-out;
    }
}

.banner-btn-left a::after {
    content: "  〉";
}

@media (max-width: 1000px) {
    .banner-btn-left {
        width: 80%;
        right: 20%;
    }

    .banner-btn-left a:hover {
        background-color: #6dc065 !important;
    
        span {
            transform: none;
            padding-bottom: 0;
        }
    
        span::after {
            opacity: 0;
            left: 0;
            right: 0;
            width: 0;
        }
    
        &::after {
            /*display: inline-block;*/
            transform: none;
        }
    }
}

.banner-btn-right {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 44%;
    left: 56%;
    max-height: 67.5px;
    overflow-y: hidden;
}

.banner-btn-right a {
    width: 100%;
    height: 100%;
    text-align: left;
    text-decoration: none;
    font-weight: 700;
    border-radius: 0;
    font-size: .8125rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: #fff;
    font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
    font-weight: 600;
    letter-spacing: .25em;
    text-transform: uppercase;
    padding-right: 1.5rem;
    transition-property: all;
    transition-duration: .26s;
    transition-timing-function: ease-in-out;

    span {
        display: inline-block;
        position: relative;
        transition-property: all;
        transition-duration: .26s;
        transition-timing-function: ease-in-out;
    }

    span::after {
        content: "";
        height: 2px;
        background-color: #fff;
        bottom: 0;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        position: absolute;
        left: 0;
        z-index: 0;
        border-radius: 0.25rem;
        opacity: 0;
        transition-property: all;
        transition-duration: .26s;
        transition-timing-function: ease-in-out;
    }
}

.banner-btn-right a:hover {
    background-color: #6dc065 !important;

    span {
        transform: translateY(-5px);
        padding-bottom: 5px;
    }

    span::after {
        // display: inline-block;
        // padding-bottom: 2px;
        // transform: translateX(3px);
        // transition-property: all;
        // transition-duration: .26s;
        // transition-timing-function: ease-in-out;
        // border-bottom: 2px solid #fff;
        opacity: 1;
        left: 20px;
        right: 40px;
        width: 92%;
    }

    &::after {
        display: inline-block;
        transform: translateY(9px);
        transition-property: all;
        transition-duration: .26s;
        transition-timing-function: ease-in-out;
    }
}

.banner-btn-right a::after {
    content: "  〉";
}

.banner-btn-right-sm a {
    font-size: .75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    letter-spacing: .25em;
    padding-right: 1rem;
}

@media (max-width: 1000px) {
    .banner-btn-right {
        width: 80%;
        left: 20%;
    }

    .banner-btn-right a:hover {
        background-color: #6dc065 !important;
    
        span {
            transform: none;
            padding-bottom: 0;
        }
    
        span::after {
            opacity: 0;
            left: 0;
            right: 0;
            width: 0;
        }
    
        &::after {
            /*display: inline-block;*/
            transform: none;
        }
    }
}