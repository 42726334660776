.fzf-body {
    padding: 0;
    margin: 0;
    width: 100vw;
    min-height: 100vh;

    .fzf-main {
        padding: 1px;
        margin: 0;
        width: 100vw;
        background-image: url('/assets/images/404-bg.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        height: 100vh;
        overflow-y: hidden;

        .fzf-container {
            display: flex;
            flex-direction: column;
            justify-content: left;
            align-items: flex-start;
            padding: 0;
            width: 100vw;
            color: #fff;
            text-align: left;
            margin: 100px 22.5% !important;
            max-width: 560px;
            height: 100%;
        
            h1 {
                font-size: 54px;
                color: #FFFFFF;
                letter-spacing: 0;
                font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
            }

            h2 {
                font-size: 30px;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: left;
                margin-top: 5px;
                font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
            }

            a {
                font-size: 18px;
                letter-spacing: 3.75px;
                text-align: center;
                line-height: 15px;
                text-decoration: none;
                transition-property: all;
                transition-duration: .26s;
                display: inline-block;
                padding: 1.25rem 1.75rem;
                color: #fff;
                font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
                font-weight: 600;
                text-transform: uppercase;
                transform: translateY(0);
                border-radius: 0;
                background-color: #2F5FAC;
                margin-top: 25px;
            }

            a:hover {
                transform: translateY(-3px);
                box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                background-color: #6dc065 !important;
            }
        }
    }
}

@media (max-width: 1000px) {
    .fzf-body {
        .fzf-main {
            .fzf-container {
                margin: 100px auto !important;
            }
        }
    }
}

@media (max-width: 600px) {
    .fzf-body {
        .fzf-main {
            .fzf-container {
                margin: 100px auto !important;
                max-width: 400px;

                h1 {
                    font-size: 40px;
                }
    
                h2 {
                    font-size: 20px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    text-align: left;
                    margin-top: 5px;
                }
    
                a {
                    font-size: 16px;
                    letter-spacing: 3.75px;
                    text-align: center;
                    line-height: 15px;
                    text-decoration: none;
                    transition-property: all;
                    transition-duration: .26s;
                    display: inline-block;
                    padding: 1rem 1.5rem;
                    color: #fff;
                    font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
                    font-weight: 600;
                    text-transform: uppercase;
                    transform: translateY(0);
                    border-radius: 0;
                    background-color: #2F5FAC;
                    margin-top: 25px;
                }
            }
        }
    }
}