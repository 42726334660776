.info-grid {
    margin-top: 50px;

    figure {
        width: 185px;

        img {
            height: 225px;
            width: 100%;
            object-fit: cover;
        }

        figcaption {
            background-color: #2f5fac;
            text-align: center;
            color: #fff;
            margin: 0;
            padding: 5px 0;
            width: 100%;
            text-transform: uppercase;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            font-size: .8125rem;
            font-weight: 700;
            font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
        }
    }
}