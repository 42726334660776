.top-banner {
    margin-bottom: 5rem;
    position: relative;
}

.blog-banner {

}

.post {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;

    .date {
        display: block;
        font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 1em;
        line-height: 22px;
        letter-spacing: 4px;
        color: #ccc;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .category {
        display: block;
        font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 13px;
        line-height: 17.875px;
        letter-spacing: 3.25px;
        color: #1c1423;
        text-transform: uppercase;
        font-weight: 700;
    }

    .title {
        font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 36px;
        line-height: 45px;
        color: #1c1947;
        margin-top: 1rem;
        margin-bottom: 4rem;
        font-weight: 600;
        position: relative;

        &::after {
            content: "";
            height: 3px;
            width: 93px;
            bottom: -32px;
            background-color: #6dc065;
            position: absolute;
            display: block;
            left: 0;
        }
    }

    .featured-image {
        max-height: 600px;
        width: 100%;
        object-position: center;
        object-fit: cover;
        margin-bottom: 4rem;
    }

    .content {
        p {
            font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 1em;
            line-height: 22px;
            color: #1c1423;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            margin-bottom: 1rem;
        }
    }

    .share-post {
        margin: 50px 0;

        h3 {
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 4px;
            line-height: 22px;
            text-transform: uppercase;
            word-spacing: 0px;
        }

        .social-share-link {
            width: 1.5rem;
            color: rgb(28, 25, 71);
            fill: currentColor;
            margin: 0.5rem;
            display: inline-block;
            transition: all .2s ease-in-out;
        }
    }

    .yt-embed {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        margin: 50px auto;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; 
        }
    }

    strong {
        font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        word-spacing: 0px;
        color: #1C1423;
    }

    h3 {
        font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 27.5px;
        word-spacing: 0px;
        color: #1C1947;

        strong {
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 20px;
            font-weight: 900;
            line-height: 27.5px;
            word-spacing: 0px;
            color: #1C1947;
        }
    }

    h4 {
        font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24.75px;
        word-spacing: 0px;
        color: #1C1947;

        strong {
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 18px;
            font-weight: 900;
            line-height: 24.75px;
            word-spacing: 0px;
            color: #1C1947;
        }
    }

    h5 {
        font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 13.28px;
        font-weight: 600;
        line-height: 18.26px;
        word-spacing: 0px;

        strong {
            font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 13.28px;
            font-weight: 700;
            line-height: 18.26px;
            word-spacing: 0px;
        }
    }
    
    ul {
        list-style: none; /* Remove default bullets */
        font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;

        li {
            font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;

            &::before {
                content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: #6DC065; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                font-size: 20px;
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 20px; /* Also needed for space (tweak if needed) */
                margin-left: -20px; /* Also needed for space (tweak if needed) */
                font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            }
        }
    }

    img {
        margin: 50px auto;
    }

    a {
        color: #1C1947;
        text-decoration: none;
        
        &:hover {
            opacity: 0.5;
        }
    }

    blockquote {
        max-width: 565px;
        border-left: 2px solid #6dc065;
        padding: 10px 20px;
        margin: 25px auto;

        em {
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 20px;
            font-style: italic;
            font-weight: 300;
            line-height: 27.5px;
            word-spacing: 0px;
            color: #1C1423;
        }

        cite {
            font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            word-spacing: 0px;
            color: #1C1423;
        }
    }

    p {
        font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 18px;
        line-height: 25px;
        word-spacing: 0px;
        color: #1C1423;
    }

    .btn {
        margin: 25px 0;
    }

    .grid-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        * {
            width: 50%;
        }
    }

    @media (max-width: 900px) {
        .grid-list {
            display: block;
            
            * {
                width: 100%;
            }
        }
    }
}