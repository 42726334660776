.sidebyside2 {
    position: relative;
}

.sidebyside2-content {
    max-width: 1200px !important;
    margin: 0 auto;

    &.wp-block-media-text {
        padding-top: 200px !important;
    }

    .wp-block-media-text__media {
        margin-top: auto;

        img {
            transform: translateX(-125px);
        }
    }

    .title {
        max-width: 600px;
        margin: 0 0 0 0 !important;
        font-weight: 700;
        font-size: 2.25rem;
        font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
        line-height: 1.25;
    }
    
    .subtext {
        max-width: 600px;
        margin: 0 0 80px 0 !important;
        font-family: 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
        line-height: 1.375;
        -webkit-font-smoothing: antialiased;
        margin-bottom: 50px !important;
    }

    .btn {
        margin: 0;
        padding: 0;
    }
    
    .btn a {
        text-decoration: none;
        transition-property: all;
        transition-duration: .26s;
        display: inline-block;
        font-size: .8125rem;
        padding: 1rem 1.5rem;
        color: #fff;
        font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
        font-weight: 600;
        letter-spacing: .25em;
        text-transform: uppercase;
        transform: translateY(0);
    }
    
    .btn a:hover {
        transform: translateY(-3px);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        background-color: #6dc065;
    }
}

.sidebyside2-content::before {
    width: 44%;
    left: 56%;
    right: 0;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    background-image: url('/assets/images/connico-pattern-tile.svg');
    background-color: #e4ebf5;
    z-index: -10;
}

@media (max-width: 900px) {
    .sidebyside2-content {
        .wp-block-media-text__media {
            img {
                transform: translateX(-75px);
            }
        }
    }
}

@media (min-width: 600px) and (max-width: 770px) {
    .sidebyside2-content::before {
        width: 10%;
        left: 90%;
    }

    .sidebyside2-content {
        .wp-block-media-text__media {
            img {
                transform: translateX(0);
            }
        }
    }
}

@media (max-width: 600px) {
    .sidebyside2-content {
        &::before {
            display: none;
        }

        &.wp-block-media-text {
            .wp-block-media-text__content {
                padding-top: 50px;
            }

            .wp-block-media-text__media {
                position: relative;
                
                img {
                    transform: translateX(0);
                    padding-left: 5%;
                }
    
                &::before {
                    width: 100%;
                    left: 5%;
                    right: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    content: "";
                    background-image: url('/assets/images/connico-pattern-tile.svg');
                    background-color: #e4ebf5;
                    z-index: -10;
                }
            }
        }

        
    }
}