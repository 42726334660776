.wp-block-pb-accordion-item {
    background-color: #e4ebf5;
    margin: 0 0 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .c-accordion__title {
        padding: 40px;
        width: 100%;
        text-align: center;
        @extend .ff-mon;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        word-spacing: 0px;
        color: #1C1423;
    }

    .c-accordion__title::after {
        content: "›";
        color: #75C36E;
        font-size: 3rem;
        font-weight: 500;
        margin-right: auto;
        position: relative;
        left: 15px;
        top: 5px;
        bottom: 0;
        transition: transform 0.5s ease-in-out;
        animation-fill-mode: both;
    }
    
    .c-accordion__content {
        padding-bottom: 6rem;
        padding-top: 2.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        max-width: 600px;
        margin: 0 auto;

        ul {
            li {
                list-style: none;
                position: relative;
                padding-left: 30px;
                margin: 15px auto;
            }
    
            li::before {
                content: "";
                width: 21px;
                height: 21px;
                top: 5px;
                left: 0;
                --bg-opacity: 1;
                background-color: #ccc;
                background-color: rgba(204,204,204,var(--bg-opacity));
                border-radius: 9999px;
                position: absolute;
                display: block;
            }
        }  
    }
}

.wp-block-pb-accordion-item.is-open {
    display: block;
    background-color: #fff;

    .c-accordion__title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .c-accordion__title::after {
        margin: 30px 20px 0 20px;
        left: 15px;
        top: -10px;
        transform: rotate(90deg);
    }
}

@media (max-width: 768px) {
    .wp-block-pb-accordion-item {
        .c-accordion__title {
            padding: 22px 46px;
            font-size: 20px;
            line-height: 20px;
        }
    }
}