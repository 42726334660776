.sidebyside {
    padding: 100px 0;
    position: relative;

    .left {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        max-width: 464px;
    }

    .right {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        max-width: 464px;
        box-sizing: border-box;
    }

    .right-unrestricted {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        box-sizing: border-box;
    }

    .title {
        font-weight: 700;
        font-size: 2.25rem;
        position: relative;
        font-weight: 600;
        font-family: "Montserrat", "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        line-height: 1.25;
        width: 464px;
    }

    .subtext {
        margin-top: 10px;
    }
    
    .callout-title {
        margin-top: 15px;
        font-weight: 700;
    }
    
    .callout-subtext {}
}

.sidebyside-bg::before {
    width: 44%;
    right: 56%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    background-image: url('/assets/images/connico-pattern-tile.svg');
    background-color: #e4ebf5;
    z-index: -10;
}

.sidebyside-bg-2::before {
    width: 44%;
    left: 56%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    background-image: url('/assets/images/connico-pattern-tile.svg');
    background-color: #e4ebf5;
    z-index: -10;
}

.sidebyside-flipped {
    padding: 0;
}

@media (max-width: 781px) {
    .sidebyside {
        .left {
            margin: 0 auto !important;
        }

        .right {
            margin: 0 auto !important;
        }
    }

    .sidebyside-flipped {
        .sidebyside-cols {
            display: flex;
            flex-direction: flex;
            justify-content: center;
            align-items: center;

            .left {
                max-width: 50%;
            }

            .right-unrestricted {
                max-width: 45%;
            }
        }
    }
}

@media (min-width: 641px) and (max-width: 770px) {
    .sidebyside-bg::before {
        width: 10%;
        right: 90%;
    }

    .sidebyside-flipped {
        padding: 25px 0;

        &.sidebyside-bg-2::before {
            width: 10%;
            left: 90%;
            right: 0;
        }

        
    }
}

@media (max-width: 640px) {
    .sidebyside-bg::before {
        display: none;
    }

    .sidebyside-flipped {
        padding: 25px 0;

        .sidebyside-cols {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;

            .left {
                max-width: 80%;
            }
    
            .right-unrestricted {
                max-width: 95%;
            }
        }

        &.sidebyside-bg-2::before {
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 50px;
        }
    }
}