.btn {
    margin: 0;
    padding: 0;
    border: none;
    // padding-top: 10px;

    &:hover {
        border: none;
    }
}

.btn a {
    text-decoration: none;
    transition-property: all;
    transition-duration: .26s;
    display: inline-block;
    font-size: .8125rem;
    padding: 1rem 1.5rem;
    color: #fff;
    font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
    font-weight: 600;
    letter-spacing: .25em;
    text-transform: uppercase;
    transform: translateY(0);
    border-radius: 0;
}

input[type="submit"].btn {
    text-decoration: none;
    transition-property: all;
    transition-duration: .26s;
    display: inline-block;
    font-size: .8125rem;
    padding: 1rem 1.5rem;
    color: #fff;
    font-family: 'Montserrat', 'Open Sans', 'century gothic', helvetica, 'Segoe UI', arial, sans-serif;
    font-weight: 600;
    letter-spacing: .25em;
    text-transform: uppercase;
    transform: translateY(0);
    border-radius: 0;
    margin-left: auto;
}

.btn a:hover, input[type="submit"].btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: #6dc065 !important;
    border: none;
}

input[type="submit"].btn::after {
    content: '〉';
}