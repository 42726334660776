.carousel-wrapper {
    margin: 0;
    padding: 0;
    position: relative;

    .gallery-left-btn {
        position: absolute;
        top: 70px;
        left: 0;
        font-family: "Open Sans", "century gothic", helvetica, "Segoe UI", arial, sans-serif;
        font-size: 16px;
        line-height: 22px;
        word-spacing: 0px;
        background-color:  #2F5FAC;
        color: #fff;
        z-index: 1;
        padding: 5px 10px 15px 10px;
        font-size: 3em;
        user-select: none;

        &:hover {
            opacity: 0.9;
            cursor: pointer;
        }
    }
}

.projectCarousel {
    max-width: 1100px;
    height: 256px;
    max-height: 256px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    color: #170724;

    --carousel-button-bg: #999;
    --carousel-button-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0
            0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);

    --carousel-button-svg-width: 20px;
    --carousel-button-svg-height: 20px;
    --carousel-button-svg-stroke-width: 2.5;

    .carousel__slide {
        display: flex;
        align-items: center;
        justify-content: center;
    
        width: 352px;
        height: 256px;
        margin-right: 6px;
        padding: 0;
    
        background-color: #eee;
        border-radius: 0;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .carousel__button {
        display: none;
    }
}

@media (max-width: 1100px) {
    .carousel-wrapper {
        margin: 25px;
        max-width: 95%;
    }
    .projectCarousel {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
    }
}